export const configuration = {
  cedApiKey: "8fcedff12601ac57a51915127e3f3d8d46fed7daf5ae62ea2c72092f773a632968759e6d32dcec86892c7511dbebf41aac01",
  environment: "development",
  version: "1.0.148",
  googleAnalytics: {

  },
  lqDataPlatform: {
    apiUrl: "https://api-dev.longeviquest.com",
  },
};